import { appleImg, searchImg, bagImg } from "../utils";
import { navLists } from '../constants';

const Navbar = () => {
  return (
    <header className="w-full flex justify-between items-center py-5 px-5 sm:px-10">
      <nav className="w-full screen-max-width flex">
        {/* Logo Image */}
        <a href="/">
          <img src={appleImg} alt="Apple" width={14} height={18} className="cursor-pointer" />
        </a>

        {/* Nav Items */}
        <div className="flex flex-1 justify-center max-sm:hidden">
          {navLists.map((item) => (
            <div className="px-5 text-sm cursor-pointer text-gray hover:text-white transition-all" key={item}>
              {item}
            </div>
          ))}
        </div>

        {/* Search Image */}
        <div className="flex items-baseline max-sm:justify-end max-sm:flex-1 gap-7">
          <img src={searchImg} alt="Search" width={18} height={18} />
          <img src={bagImg} alt="Bag" width={18} height={18} />
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
