import { footerLinks } from "../constants";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="px-5 sm:px-10 py-5">
      <div className="screen-max-width">
        <div className="">
          <p className="text-xs text-gray font-semibold">
            More ways to shop: {" "}
            <span className="underline text-blue cursor-pointer">
              Find an Apple Store {" "}
            </span>
            or {" "}
            <span className="underline text-blue cursor-pointer">
              other retailer {" "}
            </span>
            near you
          </p>
          <p className="text-xs text-gray font-semibold">
            Or call 0180-050-1966
          </p>
        </div>

        <div className="w-full h-[1px] bg-neutral-700 my-5"></div>

        <div className="flex flex-col md:flex-row md:items-center justify-between">
          <p className="text-xs text-gray font-semibold">
            Copyright @ {currentYear} Apple Inc. All rights reserved.
          </p>
          <div className="flex">
            {footerLinks.map((link, index) => (
              <p className="text-xs text-gray font-semibold" key={link}>
                {link}{" "}
                {index !== footerLinks.length - 1 && (
                  <span className="mx-2"> | </span>
                )}
              </p>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
